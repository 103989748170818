import { ActionIcon, Tooltip } from "@mantine/core";
import { IconPencil } from "@tabler/icons-react";

export const EditButton = ({
  onClick,
  disabled = false,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Tooltip label="Edit" disabled={disabled}>
      <ActionIcon
        variant="subtle"
        aria-label="Edit"
        onClick={onClick}
        disabled={disabled}
      >
        <IconPencil width="70%" height="70%" />
      </ActionIcon>
    </Tooltip>
  );
};
