import { z } from "zod";

export const bookingResponseSchema = z.object({
  retreat_id: z.string().cuid(),
  user_id: z.string().min(1),

  price_id: z.string().cuid(),
  price: z.object({
    id: z.string().cuid(),
    cents: z.number(),
  }),

  status: z.enum(["PENDING", "PAID", "CANCELLED"]),
  payment_intent: z.object({
    client_secret: z.string().min(1),
  }),

  zoom_join_url: z.string().or(z.null()),
});
