import type { ActionFunctionArgs, LoaderFunction } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { useEffect } from "react";
import { VStep } from "~/components/vsteps/VStep";
import { RetreatPrices } from "~/features/RetreatPrices";
import { DetailsStepContent } from "~/features/booking/details/DetailsStepContent";
import { PaymentStepContent } from "~/features/booking/payment/PaymentStepContent";
import { getUser } from "~/lib/auth.server";
import { useRetreatFromLoader } from "~/lib/useRetreatFromLoader";
import { useBookingQuery } from "~/queries/booking";
import { useProfileQuery } from "~/queries/profile";
import { getAndUpdateBookingAndPaymentIntent } from "~/repo/booking";

export const loader: LoaderFunction = async ({ request, params }) => {
  const retreatSlug = params.slug!;
  const user = await getUser(request);
  if (user) {
    const { booking } = await getAndUpdateBookingAndPaymentIntent(
      user,
      retreatSlug,
    );

    if (booking.status === "PAID") {
      return redirect(`/${retreatSlug}/confirmation`);
    }
  }

  return null;
};

export const action = ({ request }: ActionFunctionArgs) => {
  return redirect(request.url);
};

export default () => {
  const retreat = useRetreatFromLoader();
  const profile = useProfileQuery();
  const booking = useBookingQuery({ retreatSlug: retreat.slug });

  useEffect(() => {
    if (booking.isSuccess && booking.data.status === "PAID") {
      // Not using navigate to get the page refresh, necessary for some reason
      window.location.href = `/${retreat.slug}/confirmation`;
    }
  }, [booking, retreat]);

  const anyLoading =
    profile.isLoading || booking.isLoading || booking.data?.status === "PAID";

  const profileBookable = profile.isSuccess && profile.data.isBookable;

  return (
    <>
      <RetreatPrices retreat={retreat} />

      <h3>Booking</h3>

      <VStep
        number={1}
        title="Your details"
        active={!anyLoading}
        done={!anyLoading && profileBookable}
        loading={anyLoading}
      >
        {() => <DetailsStepContent />}
      </VStep>

      <VStep
        number={2}
        title="Payment"
        active={!anyLoading && profileBookable}
        loading={anyLoading}
      >
        {() => <PaymentStepContent />}
      </VStep>

      <VStep number={3} title="Confirmation" loading={anyLoading}>
        {() => null}
      </VStep>
    </>
  );
};
