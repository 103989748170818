import { Alert, Skeleton } from "@mantine/core";
import { DetailDisplay } from "~/components/DetailDisplay";
import { Profile } from "~/features/booking/details/Profile";
import { SignIn } from "~/features/booking/details/SignIn";
import { useAuthStateQuery, useSignOutMutation } from "~/queries/auth";

export const DetailsStepContent = () => {
  const auth = useAuthStateQuery();
  const signOut = useSignOutMutation();

  if (auth.isPending) {
    return <Skeleton height={25} radius="xl" />;
  } else if (auth.isError) {
    return <Alert color="red">Something went wrong</Alert>;
  } else if (!auth.data.signedIn) {
    return <SignIn />;
  } else {
    return (
      <>
        <DetailDisplay
          label="Email"
          onEdit={() => signOut.mutate()}
          pending={signOut.isPending}
        >
          {auth.data.user.email}
        </DetailDisplay>
        <Profile />
      </>
    );
  }
};
