import { z } from "zod";

export const profileDbSchema = z.object({
  first_name: z.string().max(50),
  last_name: z.string().max(50),
});

export const profileBookableSchema = z.object({
  first_name: z.string().min(1).max(50),
  last_name: z.string().max(50).optional().default(""),
});
