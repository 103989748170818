import { Box, Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";
import { useState } from "react";

interface Props {
  priceCents: number;
}

const CheckoutForm = ({ priceCents }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const stripeReady = stripe && elements;

  const onChange = (event: StripePaymentElementChangeEvent) => {
    const shouldDisableButton = !event.complete;
    if (disableButton !== shouldDisableButton) {
      setDisableButton(shouldDisableButton);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (submitting || !stripeReady) {
      return;
    }

    setSubmitting(true);

    const returnUrl = [
      window.location.protocol,
      "//",
      window.location.host,
      window.location.pathname,
    ].join("");

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    setSubmitting(false);

    if (error) {
      notifications.show({
        title: "The payment couldn't be processed",
        message: error.message,
        color: "red",
      });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb="md">
        <PaymentElement onChange={onChange} options={{}} />
      </Box>

      <Button
        type="submit"
        disabled={!stripeReady || disableButton}
        loading={submitting}
      >
        Pay ${(priceCents / 100).toFixed(2)}
      </Button>
    </form>
  );
};

export default CheckoutForm;
