import { Box, BoxProps, Flex } from "@mantine/core";
import { PropsWithChildren } from "react";
import { EditButton } from "~/components/EditButton";

export const DetailDisplay = ({
  label,
  pending = false,
  onEdit,
  children,
  ...boxProps
}: PropsWithChildren<
  {
    label: string;
    pending?: boolean;
    onEdit?: () => void;
  } & BoxProps
>) => {
  return (
    <Flex mb="xs" align="center" gap="sm" {...boxProps}>
      <Box component="span" c={pending ? "dimmed" : ""}>
        {label}: {children}
      </Box>

      {onEdit && <EditButton onClick={onEdit} />}
    </Flex>
  );
};
