import { useRouteLoaderData } from "@remix-run/react";
import type { z } from "zod";
import type { loader as retreatLoader } from "~/routes/$slug/route";
import { retreatPublicSchema } from "~/schemas/retreat";

export const useRetreatFromLoader = (): z.infer<typeof retreatPublicSchema> => {
  const retreatLoaderData =
    useRouteLoaderData<typeof retreatLoader>("routes/$slug");
  if (!retreatLoaderData) {
    throw new Error("Couldn't get retreat data");
  }
  return retreatPublicSchema.parse(retreatLoaderData.retreat);
};
