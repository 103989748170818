import { useState } from "react";
import { SignInForm } from "~/features/booking/details/SignInForm";

export const SignIn = () => {
  const [email, setEmail] = useState<string | null>(null);

  return email === null ? (
    <SignInForm onConfirmationSent={setEmail} />
  ) : (
    <div>
      We sent a confirmation email to {email}. Please click the link in that
      email to continue.
    </div>
  );
};
