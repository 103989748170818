import { Loader } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import classNames from "classnames";
import type { ReactNode } from "react";
import "./VSteps.css?__remix_sideEffect__";

type VStepProps = {
  title: string;
  number: number;
  done?: boolean;
  active?: boolean;
  loading?: boolean;
  children: () => ReactNode;
};

export const VStep = ({
  title,
  number,
  done = false,
  active = false,
  loading = false,
  children
}: VStepProps) => {
  const isDone = done && !loading;
  const isActive = active && !loading;

  const classes = classNames("vstep", {
    "vstep--done": isDone,
    "vstep--active": isActive,
    "vstep--loading": loading
  });

  return (
    <div className={classes}>
      <div className="vstep__head">
        <div className="vstep__icon">
          {loading ?
          <Loader color="#FFF" size="xs" /> :
          isDone ?
          <IconCheck height="60%" width="60%" stroke={2.5} /> :

          number}

        </div>
        <div className="vstep__title">{title}</div>
      </div>

      {active && <div className="vstep__content">{children()}</div>}
    </div>);

};