import { Elements } from "@stripe/react-stripe-js";
import useStripePromise from "~/clients/stripe";
import CheckoutForm from "~/features/booking/payment/CheckoutForm";
import { useRetreatFromLoader } from "~/lib/useRetreatFromLoader";
import { useBookingQuery } from "~/queries/booking";

export const StripeForm = () => {
  const retreat = useRetreatFromLoader();
  const booking = useBookingQuery({ retreatSlug: retreat.slug });
  const stripePromise = useStripePromise();

  if (booking.isSuccess) {
    return (
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: booking.data.payment_intent.client_secret }}
      >
        <CheckoutForm priceCents={booking.data.price.cents} />
      </Elements>
    );
  } else {
    return (
      <div>
        Status: {booking.status}, fetchstatus: {booking.fetchStatus}
      </div>
    );
  }
};
