import { useMutation } from "@tanstack/react-query";
import { apiClient } from "~/lib/api";

export const useSignInSendCustomMagicLink = () => {
  return useMutation({
    mutationFn: async (email: string) => {
      await apiClient.patch("/api/sign_in", { email });
    },
    mutationKey: ["auth", "sendCustomMagicLink"],
  });
};
