import {
  Alert,
  Box,
  Button,
  Group,
  LoadingOverlay,
  Switch,
  TextInput,
  Tooltip,
  rem,
} from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import { Form } from "@remix-run/react";
import { IconAt } from "@tabler/icons-react";
import { useState } from "react";
import { useSignInSendCustomMagicLink } from "~/queries/signIn";

export const SignInForm = ({
  onConfirmationSent,
}: {
  onConfirmationSent: (email: string) => void;
}) => {
  const [tosAccepted, setTosAccepted] = useState(false);
  const signInCustom = useSignInSendCustomMagicLink();

  const form = useForm({
    initialValues: { email: "" },

    // functions will be used to validate values at corresponding key
    validate: {
      email: isEmail("Invalid email"),
    },
  });

  const onSubmit = async (values: typeof form.values) => {
    const emailRedirectTo = [
      window.location.protocol,
      "//",
      window.location.host,
      window.location.pathname,
    ].join("");

    signInCustom.mutate(values.email, {
      onSuccess: () => {
        onConfirmationSent(values.email);
      },
    });
  };

  return (
    <>
      <p>
        Please enter your email address. This will be used to register you for
        the Zoom meeting the retreat will be held at.
      </p>

      <Box pos="relative">
        <LoadingOverlay visible={signInCustom.isPending} />

        <Form onSubmit={form.onSubmit(onSubmit)}>
          <Group mb="md">
            <TextInput
              type="email"
              placeholder="Your email"
              {...form.getInputProps("email")}
              leftSection={
                <IconAt
                  style={{ width: rem(16), height: rem(16) }}
                  stroke={1.5}
                />
              }
            />
            <Tooltip
              label="Please accept the terms and conditions first"
              disabled={tosAccepted}
            >
              <Button type="submit" disabled={!tosAccepted}>
                Submit
              </Button>
            </Tooltip>
          </Group>

          <Switch
            label={
              <>
                I agree to the{" "}
                <a
                  href="https://docs.google.com/document/d/1coSzW11wnSWbfkLs5GKp9q2OTn-DtQWypM4tl0LSQqE"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>
                .
              </>
            }
            name="tosAccepted"
            checked={tosAccepted}
            onChange={(event) => setTosAccepted(event.currentTarget.checked)}
            size="md"
          />
        </Form>
      </Box>

      {signInCustom.isError && (
        <Alert color="red" mt="md" title="Something went wrong">
          We're having issues sending emails right now, please try again
          tomorrow.
        </Alert>
      )}
    </>
  );
};
