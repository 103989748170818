import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { z } from "zod";
import { api, apiClient } from "~/lib/api";
import { useAuthStateQuery } from "~/queries/auth";
import { requestSchema } from "~/routes/api.booking.$retreatSlug.set_price/route";
import { bookingResponseSchema } from "~/schemas/booking";

const processBooking = (response: z.infer<typeof bookingResponseSchema>) => {
  return response;
};

type Params = { retreatSlug: string };

export const useBookingQuery = ({ retreatSlug }: Params) => {
  const [apiResource, setApiResource] = useState(api.booking({ retreatSlug }));
  const auth = useAuthStateQuery();

  useEffect(() => {
    setApiResource(api.booking({ retreatSlug }));
  }, [retreatSlug]);

  return useQuery({
    queryKey: apiResource.queryKey,
    queryFn: async () => {
      return processBooking(await apiResource.get());
    },
    retry: true,
    enabled: auth.isSuccess && auth.data.signedIn,
  });
};

export const useBookingSetPriceMutation = ({ retreatSlug }: Params) => {
  const [apiResource, setApiResource] = useState(api.booking({ retreatSlug }));

  useEffect(() => {
    setApiResource(api.booking({ retreatSlug }));
  }, [retreatSlug]);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: z.infer<typeof requestSchema>) => {
      const response = await apiClient.post(
        apiResource.url + "/set_price",
        request,
      );
      return apiResource.readSchema.parse(response.data);
    },
    mutationKey: [...apiResource.queryKey, "setPrice"],
    onSuccess: async (data, update, context) => {
      const booking = processBooking(data);
      queryClient.setQueryData(apiResource.queryKey, booking);
    },
  });
};

export const useIsBookingSetPriceMutating = ({
  retreatSlug,
}: Params): number => {
  const [apiResource, setApiResource] = useState(api.booking({ retreatSlug }));

  useEffect(() => {
    setApiResource(api.booking({ retreatSlug }));
  }, [retreatSlug]);

  return useIsMutating({ mutationKey: [...apiResource.queryKey, "setPrice"] });
};
