import { Box, Radio, Stack, Text } from "@mantine/core";
import { useRetreatFromLoader } from "~/lib/useRetreatFromLoader";
import { useBookingQuery, useBookingSetPriceMutation } from "~/queries/booking";

export const SelectPrice = () => {
  const retreat = useRetreatFromLoader();
  const booking = useBookingQuery({ retreatSlug: retreat.slug });
  const setPrice = useBookingSetPriceMutation({ retreatSlug: retreat.slug });

  return (
    <Box>
      <Text mt={0} mb="md">
        Select a price according to what you can afford:
      </Text>

      <Stack gap="md" pos="relative">
        {retreat.prices.map((price) => (
          <Radio
            key={price.id}
            checked={price.id == booking.data?.price_id}
            onChange={() => {
              setPrice.mutate({ price_id: price.id });
            }}
            label={`${price.name}: $${(price.cents / 100).toFixed(2)}`}
          />
        ))}
      </Stack>
    </Box>
  );
};
