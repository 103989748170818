import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import type { z } from "zod";
import { api } from "~/lib/api";
import { useAuthStateQuery } from "~/queries/auth";
import { profileBookableSchema } from "~/schemas/profile";

type Options = {
  query?: {
    enabled?: boolean;
    retry?: boolean;
  };
};

const processProfile = (response: z.infer<typeof api.profile.readSchema>) => {
  const profile = api.profile.readSchema.parse(response);
  const isBookable = profileBookableSchema.safeParse(profile).success;
  return { profile: profile, isBookable };
};

export const useProfileQuery = (options: Options = {}) => {
  const auth = useAuthStateQuery();

  return useQuery({
    queryKey: api.profile.queryKey,
    queryFn: async () => {
      return processProfile(await api.profile.get());
    },
    retry: false,
    staleTime: Infinity,
    enabled: auth.data?.signedIn,
  });
};

export const useProfileUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: api.profile.update,
    mutationKey: [...api.profile.queryKey, "update"],
    onMutate: async (
      update: z.infer<typeof api.profile.partialWriteSchema>,
    ) => {
      await queryClient.cancelQueries({ queryKey: api.profile.queryKey });
    },
    onSuccess: async (data, update, context) => {
      queryClient.setQueryData(api.profile.queryKey, processProfile(data));
    },
  });
};

export const useIsProfileUpdating = () =>
  useIsMutating({ mutationKey: [...api.profile.queryKey, "update"] });
