import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { browserSettings } from "~/settings";

export default function useStripePromise() {
  const [promise] = useState(() => {
    return loadStripe(browserSettings.STRIPE_PUBLISHABLE_KEY!);
  });

  return promise;
}
