import {
  Alert,
  Box,
  Button,
  LoadingOverlay,
  Skeleton,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Form } from "@remix-run/react";
import { useState } from "react";
import type { z } from "zod";
import { DetailDisplay } from "~/components/DetailDisplay";
import {
  useIsProfileUpdating,
  useProfileQuery,
  useProfileUpdateMutation,
} from "~/queries/profile";
import type { profileDbSchema } from "~/schemas/profile";

export const Profile = () => {
  const query = useProfileQuery();
  const [editing, setEditing] = useState(false);
  const isUpdating = useIsProfileUpdating();

  if (query.isLoading) {
    return <Skeleton height={28} radius="xl" maw={300} />;
  } else if (query.isError) {
    return (
      <Alert color="red" title="Couldn't load profile">
        Error: {query.error.message}
      </Alert>
    );
  }

  if (query.isSuccess) {
    if (query.data.isBookable && !editing) {
      return (
        <ProfileDisplay
          profile={query.data.profile}
          onEdit={() => setEditing(true)}
          pending={isUpdating > 0}
        />
      );
    } else {
      return (
        <ProfileForm
          profile={query.data.profile}
          onSubmitted={() => setEditing(false)}
        />
      );
    }
  } else {
    return <Alert color="red">Couldn't load profile</Alert>;
  }
};

const ProfileDisplay = ({
  pending,
  profile,
  onEdit,
}: {
  pending: boolean;
  profile: z.infer<typeof profileDbSchema>;
  onEdit: () => void;
}) => {
  return (
    <DetailDisplay label="Name" onEdit={onEdit} pending={pending}>
      {profile.first_name} {profile.last_name}
    </DetailDisplay>
  );
};

const validateName = (value: string) => {
  if (value.length < 1) {
    return "Please enter your name";
  } else if (value.length > 50) {
    return "Must not be longer than 50 characters";
  }
};

const ProfileForm = ({
  profile,
  onSubmitted,
}: {
  profile: z.infer<typeof profileDbSchema>;
  onSubmitted: () => void;
}) => {
  const update = useProfileUpdateMutation();

  const form = useForm({
    initialValues: profile,
    validate: {
      first_name: validateName,
      last_name: validateName,
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    update.mutate(values, { onSuccess: () => onSubmitted() });
  };

  return (
    <Box>
      <Text mt={0} mb="sm">
        Please enter your name, we need this to register you for the Zoom
        meeting.
      </Text>

      <Box maw={400} pos="relative">
        <LoadingOverlay visible={update.isPending} />

        <Form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput label="First name" {...form.getInputProps("first_name")} />
          <TextInput label="Last name" {...form.getInputProps("last_name")} />
          <Button type="submit" mt="sm">
            Continue
          </Button>
        </Form>
      </Box>
    </Box>
  );
};
