import axios from "axios";
import { ZodObject, ZodRawShape, z } from "zod";
import { bookingResponseSchema } from "~/schemas/booking";
import { profileDbSchema } from "~/schemas/profile";

export const apiClient = axios.create({
  responseType: "json",
});

function resource<
  TParams,
  TReadData extends ZodRawShape,
  TWriteData extends ZodRawShape,
>({
  url,
  readSchema,
  writeSchema,
  queryKey,
}: {
  url: (params: TParams) => string;
  readSchema: ZodObject<TReadData>;
  writeSchema: ZodObject<TWriteData>;
  queryKey: (params: TParams) => unknown[];
}) {
  const partialWriteSchema = writeSchema.partial();

  return (params: TParams) => ({
    get: async () => {
      const res = await apiClient.get(url(params));
      return readSchema.parse(res.data);
    },
    update: async (data: z.infer<typeof partialWriteSchema>) => {
      const res = await apiClient.patch(url(params), data);
      return readSchema.parse(res.data);
    },
    url: url(params),
    readSchema,
    writeSchema,
    partialWriteSchema,
    queryKey: queryKey(params),
  });
}

export type APIResource<
  TParams,
  TReadData extends ZodRawShape,
  TWriteData extends ZodRawShape,
> = ReturnType<typeof resource<TParams, TReadData, TWriteData>>;

type BookingParams = { retreatSlug: string };
export const api = {
  profile: resource({
    url: () => "/api/profile",
    readSchema: profileDbSchema,
    writeSchema: profileDbSchema,
    queryKey: () => ["user", "profile"],
  })(null),

  booking: resource({
    url: ({ retreatSlug }: BookingParams) => `/api/booking/${retreatSlug}`,
    readSchema: bookingResponseSchema,
    writeSchema: z.object({}),
    queryKey: ({ retreatSlug }: BookingParams) => [
      "user",
      "booking",
      retreatSlug,
    ],
  }),
};
