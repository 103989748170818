import { LoadingOverlay, Stack } from "@mantine/core";
import { SelectPrice } from "~/features/booking/payment/SelectPrice";
import { StripeForm } from "~/features/booking/payment/StripeForm";
import { useRetreatFromLoader } from "~/lib/useRetreatFromLoader";
import { useIsBookingSetPriceMutating } from "~/queries/booking";

export const PaymentStepContent = () => {
  const retreat = useRetreatFromLoader();
  const isUpdatingPrice = useIsBookingSetPriceMutating({
    retreatSlug: retreat.slug,
  });

  return (
    <Stack gap="xl" pos="relative" maw={500}>
      <LoadingOverlay visible={isUpdatingPrice > 0} />

      <SelectPrice />
      <StripeForm />
    </Stack>
  );
};
